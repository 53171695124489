import ReportsDataTable from '@/components/Report/ReportsDataTable/ReportsDataTable.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import QueryMixin from '@/mixins/query.mixin'

export default {
  mixins: [GraphQlMixin, QueryMixin],
  components: {
    Pagination,
    ReportsDataTable
  },
  data() {
    return {
      reports: [],
      reportsCount: 0,
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      search: '',
      debouncing: false,
      debounceTimeout: null
    }
  },
  computed: {
    totalPage() {
      return this.options.itemsPerPage === -1 || this.reportsCount === 0
        ? 1
        : Math.ceil(this.reportsCount / this.options.itemsPerPage)
    }
  },
  apollo: {
    reports: {
      query() {
        const fields = this.getFieldsGql('read', 'Report', [
          'id',
          'name',
          'description'
        ])
        return this.$gql`
          query Reports(
            $q: String
            $take: Int
            $skip: Int
            $orderBy: [OrderBy]
            $where: [WhereExpression]
          ) {
            reports(search: $q, take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return {
          q: this.search,
          take: this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
          skip: this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage * (this.options.page - 1),
          orderBy: this.getOrderBy(),
          where: []
        }
      },
      skip() {
        return (
          !_.get(this, 'options.page') ||
          this.debouncing ||
          this.loadingQuery ||
          !this.ability.can('read', 'Report')
        )
      },
      result({ data, error }) {
        if (data && data.reports) {
          this.reports = data.reports.items
          this.reportsCount = data.reports.totalCount
        } else if (error) {
          this.graphQLOnError(`Failed to get list of reports. ${error.toString()}`)
        }
      }
    }
  },
  created() {
    if (!this.ability.can('read', 'Report')) {
      this.$router.push('/')
    }
  }
}
