import Pagination from '@/components/Pagination/Pagination.vue'
import GraphqlMixin from '@/mixins/graphql.mixin'
import FormattingMixin from '@/mixins/formatting.mixin'
// import ReportDetails from '@/components/Report/ReportDetails/ReportDetails.vue'

export default {
  props: ['reports', 'reportsCount', 'loading', 'options', 'filter'],
  components: { Pagination },
  mixins: [GraphqlMixin, FormattingMixin],
  data() {
    return {
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200, 500] },
      localOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['updatedAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      }
    }
  },
  watch: {
    options: {
      handler(v) {
        this.localOptions = v
      },
      deep: true
    },
    localOptions: {
      handler(v) {
        this.$emit('update:options', v)
      },
      deep: true
    }
  },
  computed: {
    items() {
      if (this.loading) {
        return []
      } else {
        return this.reports
      }
    },
    itemFrom() {
      return (this.options.page-1) * this.options.itemsPerPage + 1
    },
    itemTo() {
      return Math.min(this.options.page * this.options.itemsPerPage, this.reportsCount)
    },
    totalPage() {
      return this.options.itemsPerPage === -1 || this.reportsCount === 0
        ? 1
        : Math.ceil(this.reportsCount / this.options.itemsPerPage)
    },
    displayedHeaders() {
      return []
    }
  },
  methods: {
    previousPage() {
      if (!this.loading && this.options.page > 1) {
        this.options.page--
      }
    },
    nextPage() {
      if (!this.loading && this.options.page < this.totalPage) {
        this.options.page++
      }
    }
  }
}
